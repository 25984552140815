import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wpPage;

    return (
      <Layout>
        <div className="ctn-main uk-padding">
          <ul className="uk-breadcrumb uk-margin-bottom">
            <li><Link to="/"> Homepage </Link></li>
          </ul>
          <h1 className="colored uk-text-center" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </div> {/* End .ctn-main.uk-padding */}
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      content
    }
  }
`
